<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo_eqafh_pic@2x.png" />
    <div class="skip" @click="cancel">跳过</div>
    <p class="title">选择您更多感兴趣的领域<span>（非必填）</span></p>
    <p class="total"><span>{{num}}</span>/{{total}}</p>
    <div class="block" v-for="(item, index) in list" :key="index">
      <span class="department">{{item.Name}}</span>
      <div class="department_content">
        <span v-for="(sonItem, sonIndex) in item.sonList" :key="sonIndex" @click="selectItem(index, sonIndex)" :class="{'active': sonItem.isChecked}">{{sonItem.SonName}}</span>
      </div>
    </div>
    <div class="handle">
      <div class="btn cancel" @click="cancel">取消</div>
      <div class="btn confirm" @click="confirm">确认</div>
    </div>
    <p class="reset" @click="reset"><span>重置</span></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AreaOfInterest',
  data() {
    return {
      list: [],
      checkedList: [],
      num: 0,
      total: 0
    }
  },
  computed: {
    ...mapState(['userInfo']),
    uri() {
      return this.$route.query.uri;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetDitWebCodeList').then(res => {
        this.list = res.Data;
        let index = res.Data.findIndex(item => item.Code == "other");
        if (index != -1) {
          this.list.splice(index, 1);
        }
        this.list.forEach(item => {
          this.total += item.sonList.length;
        })
        this.getCheckedList();
      })
    },
    getCheckedList() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetInteresteds?CustomerId=' + this.userInfo.Id).then(res => {
        this.checkedList = res.Data; 
        this.list.forEach(item => {
          item.sonList.forEach(sonItem => {
            this.checkedList.forEach(checkedItem => {
              if (sonItem.Id == checkedItem.SubId) {
                sonItem.isChecked = true;
              }
            })
          })
        })
        this.getCheckedNum();
        this.$forceUpdate();
      })
    },
    selectItem(index, sonIndex) {
      this.list[index].sonList[sonIndex].isChecked = !this.list[index].sonList[sonIndex].isChecked;
      this.getCheckedNum();
      this.$forceUpdate();
    },
    getCheckedNum() {
      this.num = 0;
      this.list.forEach(item => {
        item.sonList.forEach(sonItem => {
          if (sonItem.isChecked) {
            this.num++;
          }
        })
      })
    },
    cancel() {
      let sid = sessionStorage.getItem("sid");
      let key = sessionStorage.getItem("key");
      let uri = sessionStorage.getItem("uri");
      let path = sessionStorage.getItem("path");
      let redirectUrl = sessionStorage.getItem("redirectUrl");
      if (sid && uri) {
        location.href = decodeURIComponent(uri);
      } else if (key && uri) {
        location.href = decodeURIComponent(uri + '&uid=' +  this.userInfo.Id);
      } else if (this.userInfo.SubjectCode == "gu" && uri) {
        location.href = decodeURIComponent(uri);
      } else if (path) {
        this.$router.push(path);
      } else if (redirectUrl) {
        this.$router.push(redirectUrl);
        sessionStorage.removeItem('redirectUrl');
      } else {
        this.$router.push('/transferPage');
      }
    },
    confirm() {
      let checkedList = [];
      this.list.forEach(el => {
        el.sonList.forEach(item => {
          if(item.isChecked) {
            checkedList.push({
              CustomerId: this.userInfo.Id,
              SubId: item.Id,
              SubPCode: item.SonPCode,
              SubCode: item.SonCode,
              SubName: item.SonName
            })
          }
        })
      });
      this.$axios.post('/Api/Api/Web/GetWxUser/AddInterested', checkedList).then(() => {
        let sid = sessionStorage.getItem("sid");
        let key = sessionStorage.getItem("key");
        let uri = sessionStorage.getItem("uri");
        let path = sessionStorage.getItem("path");
        let redirectUrl = sessionStorage.getItem("redirectUrl");
        if (sid && uri) {
          location.href = decodeURIComponent(uri);
        } else if (key && uri) {
          location.href = decodeURIComponent(uri + '&uid=' +  this.userInfo.Id);
        } else if (this.userInfo.SubjectCode == "gu" && uri) {
          location.href = decodeURIComponent(uri);
        } else if (path) {
          this.$router.push(path);
        } else if (redirectUrl) {
          this.$router.push(redirectUrl);
          sessionStorage.removeItem('redirectUrl');
        } else {
          this.$router.push('/transferPage');
        }
      })
    },
    reset() {
      this.$axios.post('/Api/Api/Web/GetWxUser/delInterested?CustomerId=' + this.userInfo.Id).then(() => {
        this.$toast.success('重置成功');
        this.getList();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  font-family: PingFangSC-Medium, sans-serif;
  width: 100%;
  min-height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.logo {
  width: 150px;
  display: block;
  margin: 75px auto 45px;
}
.skip {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 80px;
  height: 36px;
  border-radius: 36px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  background-color: #C70C3C;
}
.title {
  font-size: 16px;
  font-weight: bold;
  span {
    color: #C70C3C;
  }
}
.total {
  font-size: 23px;
  margin-bottom: 20px;
  color: #000000;
  margin: 11px 0 17px;
  span {
    color: #C70C3C;
  }
}
.block {
  margin-bottom: 18px;
  .department {
    display: block;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin: 6px 0 0;
  }
  .department_content {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    border-bottom: 1px solid #EFEDED;
    padding: 8px 0;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: calc(calc(100% / 4) - 6px);
      height: 36px;
      background-color: #F5F6F7;
      border-radius: 36px;
      margin: 8px 3px;
      box-sizing: border-box;
    }
    .active {
      color: #FFFFFF;
      background-color: #C70C3C;
    }
  }
}
.handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  .btn {
    width: 154px;
    height: 55px;
    line-height: 55px;
    border-radius: 55px;
    text-align: center;
  }
  .cancel {
    border: 1px solid #C70C3C;
    color: #C70C3C;
    box-sizing: border-box;
  }
  .confirm {
    color: #FFFFFF;
    background-color: #C70C3C;
  }
}
.reset {
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin: 16px 0;
}
</style>